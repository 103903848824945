import { IconSetType } from "@/types/resume";

const FontAwesomeIconSet = {
  anglesUp: "fa-solid fa-angles-up fa-fw",
  anglesDown: "fa-solid fa-angles-down fa-fw",
  angleLeft: "fa-solid fa-angle-left fa-fw",
  angleRight: "fa-solid fa-angle-right fa-fw",
  download: "fa-solid fa-download fa-fw",
  filePdf: "fa-solid fa-file-pdf fa-fw",
  linkedin: "fa-brands fa-linkedin fa-fw",
  facebook: "fa-brands fa-facebook fa-fw",
  xTwitter: "fa-brands fa-x-twitter fa-fw",
  instagram: "fa-brands fa-instagram fa-fw",
  github: "fa-brands fa-github fa-fw",
  print: "fa-solid fa-print fa-fw",
  expand: "fa-solid fa-expand fa-fw",
  phone: "fa-solid fa-phone fa-fw",
  envelope: "fa-solid fa-envelope fa-fw",
  link: "fa-solid fa-link fa-fw",
  location: "fa-solid fa-location-dot fa-fw",
} as IconSetType;

export default FontAwesomeIconSet;
