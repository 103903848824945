import { JSX, useState } from "react";
import Link, { LinkProps } from "@/components/resume/links/Link";

import { isNil } from "@/utils";

type ObscureLinkProps = LinkProps;

const ObscureLink = ({
  href,
  linkText = "link",
  target = "_blank",
  ...restProps
}: ObscureLinkProps): JSX.Element | null => {
  const defaultLinkHref = "#";
  const [linkHref, setLinkHref] = useState(defaultLinkHref);

  if (!href || isNil(href)) {
    return null;
  }

  return (
    <Link
      href={linkHref}
      linkText={linkText}
      target={target}
      {...restProps}
      onFocus={() => setTimeout(() => setLinkHref(href), 25)}
      onBlur={() => setLinkHref(defaultLinkHref)}
      onMouseOver={() => setLinkHref(href)}
      onMouseOut={() => setLinkHref(defaultLinkHref)}
    />
  );
};

export default ObscureLink;
