import { checkKeyViewable, isArray, isNil } from "@/utils";

import CustomSection from "@/components/resume/lists/custom/CustomSection";
import { JSX } from "react";
import { ResumeCustomSectionListType } from "@/types/resume";
import { useResumeConfig } from "@/context/resume";

type CustomSectionsProps = {
  customSections?: ResumeCustomSectionListType;
};

const CustomSections = ({ customSections }: CustomSectionsProps): JSX.Element | null => {
  const { viewable } = useResumeConfig();

  if (
    !checkKeyViewable("custom", viewable) ||
    isNil(customSections) ||
    !isArray(customSections) ||
    (isArray(customSections) && !(customSections.length > 0))
  ) {
    return null;
  }

  return (
    <div className="cp-resume-custom-sections">
      {customSections?.map((customSection, i) => {
        return <CustomSection customSection={customSection} key={`${customSection.title.replace(" ", "_")}-${i}`} />;
      })}
    </div>
  );
};

export default CustomSections;
