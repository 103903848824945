import { checkKeyViewable, isNil, stripProtocol } from "@/utils";
import DateRange from "@/components/resume/dates/DateRange";
import HighlightsList from "@/components/resume/lists/highlights/HighlightsList";
import { JSX } from "react";
import Link from "@/components/resume/links/Link";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { ResumeCustomSectionType } from "@/types/resume";
import Section from "@/components/resume/sections/Section";
import { useResumeConfig } from "@/context/resume";

type CustomProps = {
  customSection?: ResumeCustomSectionType;
};

const CustomSection = ({ customSection }: CustomProps): JSX.Element | null => {
  const { titles, viewable, months, pipes } = useResumeConfig();

  if (!checkKeyViewable("custom", viewable) || isNil(customSection) || isNil(customSection?.kind)) {
    return null;
  }
  const heading = titles?.custom ?? customSection.title;
  const hasDates = !isNil(customSection?.startDate) || !isNil(customSection?.endDate);
  const hasPipes =
    customSection.kind.includes("pipes") ||
    !isNil(customSection?.description) ||
    hasDates ||
    !isNil(customSection?.url);

  return (
    <Section className="cp-resume-custom">
      <h2 className="cp-resume-custom-title">{heading}</h2>

      {hasPipes && (
        <Pipes>
          <PipeItem
            itemValue={customSection?.description}
            itemClassName="cp-resume-custom-description"
            itemType={pipes}
          />
          {hasDates && (
            <PipeItem
              itemValue={
                <DateRange
                  startDate={customSection.startDate}
                  endDate={customSection.endDate}
                  months={months}
                  className="cp-resume-custom-date-range"
                />
              }
              itemClassName="cp-resume-custom-dates"
              itemType={pipes}
            />
          )}
          {!!customSection?.url && (
            <PipeItem
              itemValue={<Link href={customSection.url} linkText={stripProtocol(customSection.url)} target="_blank" />}
              itemClassName="cp-resume-work-name"
              itemType={pipes}
            />
          )}
          {customSection?.items?.map((item, i) => {
            return (
              <PipeItem
                itemValue={item}
                itemClassName="cp-resume-custom-item"
                itemType={pipes}
                key={`${item.replace(" ", "_")}-${i}`}
              />
            );
          })}
        </Pipes>
      )}
      {customSection.kind.includes("highlights") && <HighlightsList highlights={customSection.highlights} />}
    </Section>
  );
};

export default CustomSection;
